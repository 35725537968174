/*.App {*/
/*  text-align: center;*/
/*}*/

body p {
    color: rgb(54, 54, 54);
}

.root {
    position: relative;
    overflow-x: hidden;
}

.burgerMenu {
    height: 100vh;
    position: fixed;
    z-index: 9999;
    width: 280px;
    top: 0;
    background: #F26522;
    right: 0;
    color: #ffffff;
    justify-content: space-between;
    transition: 0.5s;
}

.closeIconsWrapper {
    margin-left: auto;
    position: relative;
    top: 3px;
    cursor: pointer;
}

.closeIconsWrapper:hover {
    color: #F26522;
}

.burgerMenu .active div {
    background: rgba(0, 0, 0, 0.2);
    width: Calc(100% + 34px);
    margin-left: -20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.burgerMenu li div {
    width: Calc(100% + 34px);
    margin-left: -20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.burgerMenu li div:hover {
    background: rgba(0, 0, 0, 0.2);
}


.burgerMenuWrapper {
    background: #333;
    padding: 13px 15px;
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.burgerMenu ul {
    list-style: none;
    cursor: pointer;
    padding: 4px 20px;
}


.header {
    height: 50px;
    background: rgba(245, 245, 245, 0.9);
    display: flex;
    align-items: flex-end;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.header ul {
    list-style: none;
    display: flex;
    margin: 0px;
    margin-bottom: auto;
    cursor: pointer;
}

.header .active {
    color: #f26522;
    border-bottom: 1px solid #f26522;
}

.burgerWrapper {
    margin-bottom: 9px;
    cursor: pointer;
}

.header li {
    padding: 15px;
    font-size: 0.88rem;
    padding-top: 5px;
    padding-bottom: 5px;
}

.header li:hover {
    color: #f26522;
    border-bottom: 1px solid #f26522;
}

.headerWrapper {
    display: flex;
    align-items: center;
}

.header img {
    height: 39px;
}

.container {
    max-width: 1140px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.sliderWrapper::after {
    color: #fff !important;
}

.swiper-button-next {
    display: none !important;
}

.swiper-button-prev {
    display: none !important;
}


.sliderWrapper:hover .swiper-button-next {
    display: block !important;
    color: #fff
}

.sliderWrapper:hover .swiper-button-prev {
    display: block !important;
    color: #fff
}
.sliderWrapperItem1 {
    /*height: 937px !important;*/
    height: calc(100vh - 45px) !important;
    background-repeat: no-repeat !important;
    background-position: 50% 50% !important;
    background-size: cover !important;
    background: black;
    position: relative;
    width: 100%;
    display: grid;
    text-align: center;
}


.sliderWrapperItem {
    /*height: 937px !important;*/
    height: calc(100vh - 45px) !important;
    background-repeat: no-repeat !important;
    background-position: 50% 50% !important;
    background-size: cover !important;

    position: relative;
    width: 100%;
    display: grid;
    text-align: center;
}

.sliderWrapperItem h1 {
    color: #fff;
    font-weight: bold;
    font-size: 3.13rem;
    margin-top: 0px;
    margin-bottom: 2.2rem;
    text-transform: uppercase;
}

.sliderWrapperItem p {
    margin-bottom: 50px;
    margin-top: 0px;
    font-size: 1.13rem;
    color: #fff;
    line-height: 150%;
    font-weight: 100;
    font-style: italic;
}

.sliderWrapper .sliderIndicator {
    background: #fff !important;
}

.sliderWrapperContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #de500d;
}

.sliderWrapperButton {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 1rem;
    margin-top: -3rem;
    position: relative;
    z-index: 100;
}

.bounce {
    -moz-animation: bounce 3s infinite;
    -webkit-animation: bounce 3s infinite;
    animation: bounce 3s infinite;
}

@-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    60% {
        -moz-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    60% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    60% {
        -moz-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

.field {
    text-align: center;
    padding-top: 6.3rem;
    padding-bottom: 6.3rem;
    color: #363636;
}

.field h2 {
    margin-top: 0px;
    font-size: 2.2rem;

}

.fieldWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.fieldItemWrapper {
    margin-top: 3.5rem;
    display: grid;
    justify-content: center;
}

.fieldItemWrapper img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
}

.fieldItemWrapper h3 {
    margin-top: auto;
}

.solution {
    padding-top: 6.3rem;
    padding-bottom: 6.3rem;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-attachment: fixed !important;
    background-position: 50% 50% !important;
    background-color: #000 !important;
    color: #ffffff;
}

.solution h2 {
    margin-bottom: 3.5rem;
    font-size: 2.5rem;
    text-align: center;
}

.solutionWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.solutionItemWrapper {
    display: grid;
    grid-template-columns: 30px  1fr;
    grid-column-gap: 10px;
}

.solutionItemWrapper h3 {
    margin: 0px;
    text-align: left;
    font-size: 1.57rem;
}

.solutionItemWrapper div {
    display: flex;
    align-items: center;
    border: 1px solid;
    border-radius: 50px;
    justify-content: center;
    margin-bottom: 3rem;
    width: 30px;
    height: 30px;
    margin-top: 6px;
}

.services {
    padding-top: 6.3rem;
    padding-bottom: 6.3rem;
}

.services h2 {
    text-align: center;
    font-size: 2.2rem;
    font-weight: 700;
    color: #363636;
}

.servicesWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.servicesWrapper h3 {
    margin-top: 5px;
    margin-bottom: 25px;
    font-size: 1rem;
    font-weight: normal;
}

.servicesWrapperItem {
    display: grid;
    grid-template-columns: 53px 1fr;
    max-width: 360px;
    padding-right: 13%;
    margin-top: 3rem;
}

.servicesWrapperItem:hover svg {
    color: rgb(242, 101, 34);
}

.servicesWrapperItem:hover h3 {
    color: rgb(242, 101, 34);
}

.professionalWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

}

.professionalWrapper h3 {
    font-size: 3.13rem;
    color: rgb(242, 101, 34);
    font-weight: normal;
    line-height: 1.2;
    margin-top: 0px;
    margin-bottom: 1.88rem;
}

.professionalWrapperContent {
    padding-left: 6.9%;
    padding-right: 6.9%;
    max-width: 509px;
    padding-top: 6.3rem;
    padding-bottom: 6.3rem;
    background-size: cover;
    background-position: center;
}

.portfolioTitle {
    text-align: center;
    font-size: 2.2rem;
    margin-top: 35px;
    margin-bottom: 35px;
}

.portfolioWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-left: 6.9%;
    padding-right: 6.9%;
}

.portfolioWrapper {
    color: #ffffff;
    padding-bottom: 20px;
}

.portfolioWrapper a {
    text-decoration: none;
    color: #ffffff;
}

.burgerWrapper:hover {
    color: rgb(242, 101, 34);
}

/*rgb(242, 101, 34)*/

.portfolioItems {
    height: 20rem;
    background-position: bottom !important;
    background-size: cover !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s;
    cursor: pointer;
}



.portfolioItemsMedis {
    background-position: center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important
}

@media  (min-width: 900px){
    .portfolioItems:hover {
        transform: scale(1.2);
        background-color: rgba(242, 101, 34, 0.8);
    }
}


.portfolioItems a {
    color: white;
    text-decoration: none;
}


.portfolioItemsContainer {
    padding: 0.9rem;
    display: none;
}

.portfolioItems:hover .portfolioItemsContainer {
    background-color: rgba(242, 101, 34, 0.8);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.portfolioWrapperImg {
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.portfolioItemsContainer h3 {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 1.25rem;
}

.portfolioItemsContainer h4 {
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
}

.portfolioWrapperButton {
    text-align: center;
    padding-top: 45px;
    padding-bottom: 45px;
}

.portfolioWrapperButton button {
    cursor: pointer;
    border: 2px solid #f26522;
    text-transform: uppercase;
    border-radius: 0;
    font-size: 16px;
    height: 45px;
    width: 100%;
    max-width: 245px;
    color: #f26522;
    transition: 0.5s;
}

.portfolioWrapperButton button:hover {
    background: #f26522;
    color: #fff;
}

.portfolioWrapperButton svg {
    margin-right: 5px;
    margin-top: -2px;
}


.statisticsWrapper {
    padding-top: 6rem;
    padding-bottom: 6rem;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-attachment: fixed !important;
    background-position: 50% 50% !important;
    background-color: rgb(242, 101, 34) !important;
    color: #ffffff;
}


.statisticsWrapperContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
}

.statisticsWrapperContainer span {
    font-size: 3.75rem;
    margin-bottom: 10px;
}

.statisticsWrapperContainer h3 {
    margin: 0;
    font-weight: normal;
}

.teamWrapper {
    padding-top: 6.3rem;
    background: #F9F9F9;
    padding-bottom: 6.3rem;
}

.teamWrapperRoot {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
}

.teamWrapperItems {
    background-color: #ffffff;
    padding: 30px;
    cursor: pointer;
}

.teamWrapperItems:hover {
    background-color: #f26522;
    color: #ffffff;
}

.teamWrapperItems:hover h3 {
    color: #ffffff;
}

.teamWrapperItems:hover p {
    color: #ffffff;
}

.gooleImgColor {
    display: none;
}

.teamWrapperItems:hover .gooleImg {
    display: none;
}

.teamWrapperItems:hover .gooleImgColor {
    display: block;
}


.teamWrapper h2 {
    margin-bottom: 5.9%;
    font-size: 2.2rem;
    text-align: center;
}

.teamWrapperItems h3 {
    font-size: 1.13rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #f26522;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 500;
}

.teamWrapperItems h4 {
    color: #363636;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: normal;
}

.teamWrapperItems p {
    margin: 0;
}

.teamWrapperIcons {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.facebookImg {
    margin-right: 10px
}

.teamWrapperIcons img {
    height: 20px;
    width: 20px;
}

.teamContainer {
    padding-right: 10px;
    margin-left: 20px;
    display: grid;
    grid-template-rows: 50px 50px 1fr;
}

.teamPhoto {
    width: 135px;
    height: 135px;
    border-radius: 50%;
    background-position: center !important;
    background-size: cover !important;
}

.teamWrapperImgPhoto {
    border-radius: 50%;
    float: left;
    margin-right: 20px;
}

.photoWrapperItems {
    height: 135px;
    border-radius: 50%;
    float: left;
    margin-right: 20px;
}


.wrapperForm {
    padding-top: 6.3rem;
    padding-bottom: 6.3rem;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-attachment: fixed !important;
    background-position: 50% 50% !important;
    background-color: #000 !important;
    color: #ffffff;
}

.wrapperForm h3 {
    color: #f26522;
    margin-top: 0px;
    margin-bottom: 6.1rem;
    font-size: 2.2rem;
    text-align: center;
}

.wrapperFormButtonWrapper {
    text-align: center;
}

.wrapperFormButtonWrapper button {
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 0;
    width: 100%;
    max-width: 160px;
    height: 45px;
    transition: 0.5s;
}

.wrapperFormAccount {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
}

.wrapperFormButtonWrapper input {
    color: #ffffff;
}

.wrapperFormIcons {
    margin-bottom: 0px;
    position: absolute;
}

.wrapperFormMesage {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer {
    background-color: #f26522;
    color: #ffffff;
    padding-top: 3.13rem;
    padding-bottom: 3.13rem;
}

.footer h3 {
    margin-top: 0;
    margin-bottom: 10px;
}

.footer p {
    margin: 0;
    color: #ffffff;
}

.footer a {
    text-decoration: none;
    color: #ffffff;
}

.footerWrapper {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
}

.footerItemsWrapper {
    display: grid;
    grid-template-columns: 30px 1fr;
    margin-bottom: 5px;
}

.footerItemsWrapper a:hover p {
    color: #373636;
}


.footerItemsWrapper:hover {
    color: #373636;
}

.wrapperMessengers {
    display: flex;
    justify-content: flex-end
}

.wrapperMessengersTitle {
    font-weight: normal;
}

.wrapperMessengers a {
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.wrapperMessengers a:hover {
    color: #373636;
}

.gooleImgMessengers {
    display: none;
}

.wrapperMessengers a:hover .gooleImgMessengers {
    display: block;
}

.wrapperMessengers a:hover .gooleImgColorMessengers {
    display: none;
}

.footerLanguageWrapper {
    background-color: #de500d;
    padding: 15px 0;
    font-size: 14px;
    color: #ffffff;
}

.footerLanguageWrapper p {
    color: #ffffff;
    margin: 0;
}

.footerLanguageRoot {
    display: flex;
    justify-content: space-between;
}

.footerLanguageRoot span {
    cursor: pointer;
    font-weight: 500;
}

.languageActive {
    margin-right: 15px;
    color: #373636;

}

.languageItem:hover {
    color: #373636;
}

.languageWrapper {
    min-width: 16.7%;
}


#accordion p {
    text-indent: 15px;
    padding-top: 1.5em;
}

#accordion li {
    margin-left: 50px;
    padding-top: 1em;
}

#accordion div.module {
    background: #eeeeee;
    border: 1px solid #cccccc;
    padding: 5px 10px;
    margin-top: 1.5em;
}


@media (max-width: 900px) {
    .header ul {
        display: none;
    }

    .professionalWrapper {
        grid-template-columns: 1fr;
    }

    .sliderWrapperItem h1 {
        font-size: 1.13rem;
    }

    .sliderWrapperItem p {
        font-size: 1rem;
    }

    .sliderWrapperItem {
        height: 100vh !important;
    }

    .fieldWrapper {
        grid-template-columns: 1fr;
    }

    .fieldItemWrapper {
        margin-top: 0rem;
    }

    .fieldItemWrapper img {
        margin-bottom: 1rem;
    }

    .field h2 {
        margin-bottom: 3rem;
    }

    .fieldItemWrapper h3 {
        margin-bottom: 3rem;
    }

    .solution h2 {
        font-size: 2.37rem
    }

    .solutionWrapper {
        grid-template-columns: 1fr;
    }

    .solutionItemWrapper h3 {
        font-size: 1.27rem;
    }

    .solutionItemWrapper {
        grid-column-gap: 15px;
    }

    .servicesWrapper {
        grid-template-columns: 1fr;
    }

    .servicesWrapper h3 {
        margin-top: -2px;
    }

    .professionalWrapperBackground {
        height: 300px;
    }

    .professionalWrapper h3 {
        font-size: 1.9rem
    }

    .statisticsWrapperContainer span {
        font-size: 3rem;
    }

    .statisticsWrapperContainer h3 {
        font-size: 0.9rem;
    }

    .teamWrapperRoot {
        grid-template-columns: 1fr
    }

    .servicesWrapperItem {
        max-width: 100%;
    }

    .teamWrapperItems {
        padding: 15px;
        text-align: center;
    }

    .photoWrapperItems {
        float: none;
    }

    .teamWrapperImgPhoto {
        float: none;
    }

    .headerWrapper {
        width: 100%;
    }

    .burgerWrapper {
        margin-left: auto;
    }

    .footerLanguageRoot {
        display: block;
    }

}

/*@media (max-width: 400px) {*/

/*    .footerLanguageWrapper  .container{*/
/*        width: fit-content;*/
/*    }*/
/*}*/

@media (max-width: 1200px) {
    /*.wrapperForm .container{*/
    /*    width: fit-content;*/
    /*}*/
    .container {
        width: Calc(100% - 60px);
    }
}

@media (max-width: 500px) {
    .footerWrapper {
        display: block;
    }

    .wrapperMessengersRoot {
        margin-top: 2.5rem;
    }

    .wrapperMessengers {
        justify-content: end;
    }

    .wrapperMessengers a {
        margin-left: 0;
        margin-right: 20px;
    }

    .portfolioWrapper {
        display: block;
    }
}


@media (min-width: 900px)  and (max-width: 1700px) {
    .sliderWrapperItem {
        height: 80vh !important;
    }

    .portfolioItems:hover h4 {
        transform: scale(0.8);
    }

    .portfolioItems:hover h3 {
        transform: scale(0.8);
        text-align: center;
    }
}

@media (min-width: 766px)  and (max-width: 900px) {
    .portfolioWrapper {
        grid-template-columns: 1fr 1fr;
    }

    .container {
        padding-left: 30px;
        padding-right: 30px;
    }

    .servicesWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 766px) {
    .portfolioWrapper {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 350px) {
    .teamWrapperItems {
        min-height: 35rem
    }
}

@media (min-width: 350px)  and (max-width: 400px) {
    .teamWrapperItems {
        min-height: 32rem
    }
}

@media (min-width: 400px)  and (max-width: 500px) {
    .teamWrapperItems {
        min-height: 29.5rem
    }
}

@media (min-width: 500px)  and (max-width: 900px) {
    .teamWrapperItems {
        min-height: 26.5rem
    }

    .portfolioWrapper {
        display: block !important;
    }
}
